<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 style="font-weight: 800; color: #e53935">ทะเบียนประกาศ</h1>
      </v-col>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-col cols="4" class="py-0 my-0"
            ><span>ค้นหาจาก</span>
            <v-select
              v-model="searchType"
              :items="searchTypeItems"
              placeholder="ค้นหาจาก"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="4" class="py-0 my-0">
            <span>ค่าค้นหา</span>
            <v-text-field v-model="searchText" dense outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="4" class="py-0 my-0"
            ><span>วันที่ออกประกาศ</span>
            <v-menu
              v-model="menufrom1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom1"
                  label=""
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  clearable
                  @click:clear="dateFrom1 = null"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title
                v-model="dateFrom1"
                @input="menufrom1 = false"
                @click="$refs.menufrom1.save(dateFrom1)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" class="py-0 my-0">
            <span>ถึง</span>
            <v-menu
              v-model="menufrom2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom2"
                  label=""
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  clearable
                  @click:clear="dateFrom2 = null"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title
                v-model="dateFrom2"
                @input="menufrom2 = false"
                @click="$refs.menufrom2.save(dateFrom2)"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn @click="clearData()" class="mr-4">เคลียร์</v-btn
          ><v-btn color="#0074AA" dark @click="searchData()">ค้นหา</v-btn>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <!-- <v-col cols="4"  class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer> -->
          <v-col class="text-right">
            <ExportForm1
              :data="items"
              name="ทะเบียนประกาศ"
              :jsonFields="jsonFields"
            />
            <v-btn
              @click="goToCreate()"
              color="#0074AA"
              dark
              v-show="userdata.user.isEdit"
              >เพิ่มทะเบียนประกาศ</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersForm1getAllForm3"
            :items="items"
            :search="search"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.Form1getAllForm3Img`]="{ item }">
              <v-img
                :src="item.Form1getAllForm3Img"
                width="120px"
                class="ma-1"
              ></v-img>
            </template>
            <template v-slot:[`item.activeFlag`]="{ item }">
              <v-chip class="orange lighten-2" v-if="item.activeFlag == true"
                >ใช้งาน</v-chip
              >
              <v-chip
                class="grey lighten-9 white--text"
                v-if="item.activeFlag == false"
                >ไม่ใช้งาน</v-chip
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="ViewCate(item)" v-show="userdata.user.isView"
                  >mdi-eye</v-icon
                >
                <v-icon @click="UpdateCate(item)" v-show="userdata.user.isEdit"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteCate(item)">mdi-delete</v-icon>
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import ExportForm1 from "@/views/ExportExcel/ExportForm1.vue";
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
export default {
  components: {
    draggable,
    Loading,
    ExportForm1,
  },
  data() {
    return {
      userdata: [],
      jsonFields: {
        วันที่ลงส่งหนังสือ: "dateFrom",
        วันที่ออกหนังสือ: "dateTo",
        หน่วยงาน: "agency",
        เรื่อง: "subject",
        ผู้ลงนามสั่งการ: "orderWhoName",
        ไฟล์แนบ: "file",
        หมายเหตุ: "remark",
        การปฏิบัติ: "practice",

        // count: 1
        // createdAt: (...)
        // id: (...)
        // updatedAt: (...)
        // year: (...)
      },
      headersForm1getAllForm3: [
        // { text: "", value: "", align: "center", width: "50" },
        { text: "ลำดับที่ประกาศ", value: "count", align: "center" },
        // { text: "เลขที่หนังสือ", value: "bookNo", align: "center" },
        // { text: "วันที่ลงส่งหนังสือ", value: "dateFrom", align: "center" },
        { text: "วันที่ออกประกาศ", value: "dateTo", align: "center" },
        // { text: "หน่วยงาน", value: "activeFlag", align: "center" },
        // { text: "ถึง", value: "activeFlag1", align: "center" },
        { text: "เรื่อง", value: "subject", align: "center" },
        { text: "หน่วยงาน", value: "agency", align: "center" },
        { text: "หมายเหตุ", value: "remark", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center", width: 200 },
      ],
      items: [
        {
          sortIndex: "1",
          sortIndex1: "มท 0804.3/ว 1792",
          Form1getAllForm3: "01-09-2021",
          description: "02-09-2021",
          activeFlag: "ทต เวียงสา",
        },
      ],
      searchType: "",
      searchTypeItems: [
        { text: "--เลือก--", value: "" },
        // { text: "เลขที่หนังสือ", value: "bookNo" },
        // { text: "วันที่ลงส่งหนังสือ", value: "dateFrom" },
        { text: "วันที่ออกประกาศ", value: "dateTo" },
        { text: "เรื่อง", value: "subject" },
        { text: "หน่วยงาน", value: "agency" },
        // { text: "ถึง", value: "formTo" },
      ],
      searchText: "",
      search: "",
      sortIndex: 0,
      loading: false,

      menufrom1: false,
      dateFrom1: "",
      menuto1: false,
      dateTo1: "",

      menufrom2: false,
      menuto2: false,
      dateFrom2: "",
      dateTo2: "",
      year: "",
    };
  },
  created() {
    this.year = JSON.parse(Decode.decode(localStorage.getItem("yearSelected")));
    this.userdata = JSON.parse(
      Decode.decode(localStorage.getItem("usersaratangjai"))
    );
    this.getAllForm3();
  },

  methods: {
    clearData() {
      this.searchType = "";
      this.searchText = "";
      this.dateFrom1 = null;
      this.dateFrom2 = null;
      // this.searchData();
    },
    async searchData() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/forms/form3?searchType=` +
          this.searchType +
          "&searchText=" +
          this.searchText +
          "&year=" +
          this.year
      );
      console.log("getAllForm3", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        // console.log(this.list);
      }
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("usersaratangjai"))
      );
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/categories/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllForm3() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/forms/form3?year=` + this.year
        // 'http://localhost:8081/categories/'
      );
      console.log("getAllForm3", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    UpdateCate(val) {
      localStorage.setItem("form3data", Encode.encode(val));
      this.$router.push("EditForm3");
    },
    ViewCate(val) {
      localStorage.setItem("form3data", Encode.encode(val));
      this.$router.push("viewForm3");
    },
    async DeleteCate(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("usersaratangjai"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/forms/form3/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllForm3();
        }
      });
    },
    goToCreate() {
      this.$router.push("form3");
    },
  },
};
</script>
